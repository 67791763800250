<template>
  <v-container>
    <DetallesSolicitud :cartLoadable="cart">
    <template v-slot:body>
        <v-alert v-if="authTimeExpired && !cart.data?.solicitud.autorizada" dense type="info">
          El tiempo de autorización ha expirado.
        </v-alert>
        <template v-if="showAuthorizationFiles">
          <div class="d-flex justify-center align-center">
            <v-card-title>
              <v-icon class="mr-2 black--text" size="40" >mdi mdi-list-box-outline</v-icon>
              <span class="black--text text-h4 font-weight-bold text-uppercase">Ordenes de compra</span>
            </v-card-title>
          </div>
          <div v-for="(fund, index) in fundItems" :key="index">
            <v-card class="px-2 py-2 pb-2 mb-6" elevation="8">
                <div class="text-md-center mb-4 mt-4">
                  <span class="text-h5 font-weight-bold">{{ fund.nombre_comercial }}</span>
                </div>
                  <!-- COMIENZA LISTADO DE PRODUCTOS POR PROVEEDOR -->
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div class="d-flex align-center">
                          <v-icon>mdi mdi-shopping-outline</v-icon>
                          <v-col class="flex-grow-1">
                            <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">Productos</b>
                            <br />
                            <span class="d-inline-block mt-1">
                                Total: ${{ total(fund) }}
                            </span>
                        </v-col>
                        </div>
                      </v-expansion-panel-header>
                      <v-divider/>
                      <v-expansion-panel-content>
                        <v-expansion-panel v-for="product in fund.productos" :key="product.id">
                          <div class="d-flex justify-space-between align-center flex-wrap px-4 py-4" style="gap: 16px;">
                              <div class="d-flex align-center flex-wrap" style="gap: 16px;">
                                  <img class="mx-auto" style="width: 50px; height: 50px; object-fit: cover; float: left;" :src="product.foto" :alt="product.nombre" />
                                  <div>
                                      <p class="mb-0 text-h6 font-weight-bold text-uppercase" style="line-height: 1.5rem !important">{{ product.nombre }}</p>
                                      <div v-for="oferta in product.ofertas" :key="oferta.id">
                                          <span> Unidad de medida: {{ oferta.uni?.nombre ? oferta.uni?.nombre : '-' }} </span>
                                          <br>
                                          <span> Marca: {{ oferta.marca ? oferta.marca : '-' }} </span>
                                          <br>
                                          <span> Cantidad: {{ product.cantidad ? product.cantidad : '-' }} </span>
                                          <br>
                                          <span v-if="oferta.precio_con_iva">
                                            Precio de oferta: ${{ oferta.precio }}
                                          </span>
                                          <span v-else>
                                            Precio de oferta (con IVA): ${{ (oferta.precio * 1.13).toFixed(2) }}
                                          </span>
                                          <br>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </v-expansion-panel>
                      </v-expansion-panel-content>
                      <div class="px-4 py-4 mt-2">
                        <AdjuntosProcesoAutorizacion 
                        :request-id="requestId" 
                        :read-only="cart.data?.solicitud.autorizada || authTimeExpired"
                        :idProveedor="fund.id"
                      />
                      </div>
                    </v-expansion-panel>
                  </v-expansion-panels>
            </v-card>
          </div>
        </template>
      </template>
      <!-- <template v-slot:items v-if="showCertificationComponent && cart.data?.solicitud">
        <h2 class="mb-2">Certificación de fondos</h2>
        <v-divider class="mb-4" />
        <v-alert v-if="authTimeExpired && !cart.data?.solicitud.autorizada" dense type="info">
          El tiempo de autorización ha expirado. Al descartar la solicitud, toda certificación realizada
          será cancelada.
        </v-alert>
        <CertificacionInsumosSolicitudCm 
          :request-id="requestId"
          :auth-time-expired="authTimeExpired"
          :read-only="!shouldCertify || cart.data.solicitud.autorizada || authTimeExpired || cart.data.bitacora_activa.bitSolCom_seq.rol !== 'ROLE_CM_SOLICITUD_LIST_JUFI'"
        />
      </template> -->
      <template v-if="!cart.isLoading" v-slot:actions>
        <v-tooltip v-for="(item, index) in mapButtons(cart.data.solicitud)" :key="index" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on" 
              v-bind="attrs"
              @click="item.action"
              :loading="operation.isLoading"
              class="primary"
            >
              {{ item.text }}
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </template>
    </DetallesSolicitud>
    <v-dialog v-model="isFormModalOpen" max-width="600px">
      <component
          v-if="activeStep"
          :is="`${activeStep.formulario}-form`"
          @on-save="openConfirm"
          @on-cancel="isFormModalOpen = false"
      />
    </v-dialog>
    <ConfirmationModalComponent
        :isOpen="confirmationModal.isOpen"
        :description="confirmationModal.description"
        :isLoading="operation.isLoading"
        @confirm="handleConfirmation"
        @cancel="clearConfirmation"
    />
  </v-container>
</template>
<script>
import { 
  AdjuntoForm, 
  CantidadAdjuntoForm, 
  CantidadForm, 
  ComentarioForm, 
  FechaForm, 
  ComentarioAdjuntoForm, 
  FechaAdjuntoForm 
} from '@/components/CMForms';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import CertificacionInsumosSolicitudCm from './CertificacionInsumosSolicitudCm.vue';
import AdjuntosProcesoAutorizacion from './AdjuntosProcesoAutorizacion.vue';
import DetallesSolicitud from '@/components/DetallesSolicitud.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { mapActions, mapMutations, mapState } from "vuex";
import { removePropsFromObjectIfNil } from '@/utils/data';
import { Observable, Listener } from '@/utils/observable.js';
import { Countdown } from '@/utils/countdown';
import { convertToFormData } from '@/utils/data';

const initialConfirmationModal = {
  isOpen: false,
  description: '',
  data: null,
};

export default {
  name: "CMProcesoAprobacionCompraElectronicaDetalle",
  components: {
    ConfirmationModalComponent,
    DetallesSolicitud,
    AdjuntoForm, 
    CantidadAdjuntoForm, 
    CantidadForm, 
    ComentarioForm, 
    FechaForm,
    ComentarioAdjuntoForm,
    FechaAdjuntoForm,
    CertificacionInsumosSolicitudCm,
    AdjuntosProcesoAutorizacion,
  },
  data: () => ({
    operation: createLoadable(null),
    buttons: createLoadable([]),
    requestId: null,
    showButtons: false,
    bitacora: [],
    activeStep: null,
    isFormModalOpen: false,
    confirmationModal: initialConfirmationModal,
    // archivos UCP
    archivosUcp: new Map(),
  }),
  computed: {
    ...mapState("cmAprobacionCompraElectronica", ["cart", "funds","productosPorProveedor", "adjuntosUcp"]),
    errorWhenAlteringState() {
      return this.operation.error?.message;
    },
    fundItems(){
      return this.productosPorProveedor.data
    },
    allFundsHaveUploadedFile() {
      return this.funds.data.every((fund) => fund.adjuntos.length > 0);
    },
    todosLasOrdenesDeCompraCargadas() {
      return this.productosPorProveedor.data.every(productoProveedor =>
        productoProveedor.productos.every(producto =>
          producto.ofertas.every(oferta =>
            oferta.cmpsc.every(cmpsc => cmpsc.id_orden_compra)
          )
        )
      );
    },
    todosLosArchivosUcpCargados() {

      const proveedores = Array.from(this.adjuntosUcp.keys());

      return proveedores.every((proveedor) => this.adjuntosUcp.get(proveedor).data.length > 0);
    },
    allFundsAreCertified() {
      return this.funds.data.every((fund) => {
        return fund.productos.every((producto) => producto.certificacion);
      });
    },
    authTimeExpired() {
      return this.buttons.data.length === 1 && this.buttons.data[0].id === 'BTN-CADUCAR';
    },
    mostrarSoloBotonesRetrocesoAlCertificar() {
      if (!this.shouldCertify) return false;

      return !(this.funds.data.length > 0 && this.allFundsAreCertified && this.allFundsHaveUploadedFile)
    },
    showCertificationComponent() {
      return this.haveRoles(['ROLE_CM_CERTIFICACION_VIEW', 'ROLE_CM_CERTIFICACION_SOLO_LECTURA_VIEW']);
    },
    shouldCertify() {
      return this.haveRole('ROLE_CM_CERTIFICACION_VIEW');
    },
    showAuthorizationFiles() {
      return this.haveRole('ROLE_CM_ARCHIVOS_APROBACION_JUCP_VIEW');
    },
  },
  methods: {
    ...mapActions("catalogoEnLinea", ["getOpenRequest"]),
    ...mapMutations("catalogoEnLinea", ["setFeedbackModalConfig"]),
    ...mapMutations("cmAprobacionCompraElectronica", ["resetearProductosProveedor"]),
    ...mapActions("cmAprobacionCompraElectronica", ["loadCart","cargarProductoPorProveedor"]),
    mapButtons(request) {        
        if (!request) return [];
        let filterButtons = [];

        if (this.authTimeExpired) {
          filterButtons = this.buttons.data.filter(b => b.id === 'BTN-CADUCAR');
        } else {
          filterButtons = this.buttons.data.filter(b => b.id !== 'BTN-EDITAR');
        }

        const buttons = filterButtons.map((item) => ({
          text: item.accion_requerida,
          id: item.id,
          id_estado_solicitud: item.id_estado_solicitud,
          accion: item.accion_requerida,
          action: () => this.handleStep(item),
          retroceso_en_flujo: item.retroceso_en_flujo,
        }));

        return (!this.todosLosArchivosUcpCargados || !this.todosLasOrdenesDeCompraCargadas) 
          ? buttons.filter((button) => button.id_estado_solicitud !== 5) 
          : buttons;
    },
    async loadButtonActions() {
      if (!this.haveRole('ROLE_CM_SOLICITUD_APROBAR')) return;
      toggleLoadable(this.buttons);
      const { data } = await this.services.cmAprobacionSolicitudCompra.obtenerBotones(this.requestId);

      setLoadableResponse(this.buttons, data);
    },
    clearConfirmation() {
      this.confirmationModal = initialConfirmationModal;
    },  
    openConfirm(data, msg) {
      this.isFormModalOpen = false;
      this.confirmationModal = {
        isOpen: true,
        description: msg ?? `¿Desea ${this.activeStep.accion_requerida} esta solicitud?`,
        data: removePropsFromObjectIfNil(data, ['comentario', 'adjunto']),
      };
    },
    handleStep(step) {
      this.activeStep = step;

      if (!step.formulario) return this.openConfirm(null);

      this.isFormModalOpen = true;
    },
    handleConfirmation() {
      const { id } = this.activeStep;
      
      switch(id) {
        case 'BTN-ACTIVAR':
          this.performFixedAction(true);
          break;
        case 'BTN-CADUCAR':
          this.performFixedAction();
          break;
        case 'BTN-EDITAR':
          this.performFixedAction();
          break;
        default:
            this.performRequestStateAction();
          break;
      }
    },
    showFeedbackModal(message, path) {
      this.setFeedbackModalConfig({
        isOpen: true,
        type: "success",
        description: message,
        buttonText: "Aceptar",
        onClose: () => this.$router.push(path),
      });
    },
    async performRequestStateAction() {
      toggleLoadable(this.operation);
      const formData = convertToFormData({
        ...this.confirmationModal.data,
        paso: this.activeStep.id,
      });

      
      const { data } = await this.services.cmAprobacionSolicitudCompra.alterarEstado(this.requestId, formData);
      setLoadableResponse(this.operation, data);
      this.confirmationModal = initialConfirmationModal;
      if (!isResponseSuccesful(data)) return

      this.showFeedbackModal(data.message, '/cm-aprobacion-solicitud-compra');
    },
    async performFixedAction(isActivateAction) {
      this.confirmationModal.isOpen = false;
      toggleLoadable(this.operation);

      const action = isActivateAction 
        ? this.services.CMSolicitudesConObservacion.markRequestAsActive
        : this.services.PurchaseRequest.markRequestAsClosed;

      const { data } = await action(this.requestId);
      this.isConfirmationOpen = false;
      setLoadableResponse(this.operation, data);

      if (!isResponseSuccesful(data)) return;

      this.showFeedbackModal(data.message, '/cm-aprobacion-solicitud-compra');
      this.getOpenRequest();
    },
    total(proveedor) {
      const filteredFunds = this.fundItems.filter(funds => funds.id === proveedor.id);

      const totalMontoProducto = filteredFunds.reduce((total, funds) => {
        funds.productos.forEach(producto => {
          total += +producto.monto_producto;
        });
        return total;
      }, 0);

      return Number(totalMontoProducto).toFixed(2);
    },

  },
  watch: {
    errorWhenAlteringState(value) {
      if (value) this.loadButtonActions();
    },
  },
  created() {
    const requestId = this.$route.params.id;
    this.requestId = Number(requestId);
    this.cargarProductoPorProveedor(this.requestId);
    this.loadCart(requestId);
    this.loadButtonActions();
  },
  mounted(){
    const listener = new Listener('detalle').setHandler(() => {
      this.loadButtonActions();
      this.cargarProductoPorProveedor(this.requestId);
    });
    this.unsubscribe = Observable.getInstance().subscribe('refresh-adjuntos', listener);
  },
  beforeDestroy() {
    Countdown.getInstance().clear();
    this.unsubscribe?.();
    this.resetearProductosProveedor();
  },
}
</script>
