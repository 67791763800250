<template>
  <v-container>
    <v-row>
      <SpinnerComponent :spinning="cartLoadable.isLoading" text="Cargando solicitud..."  />
      <v-col v-if="!cartLoadable.isLoading" cols="12" md="12">
        <v-card>
          <v-card-title>
            <h1>Solicitud detalle</h1>
          </v-card-title>

          <v-card-text v-if="request">
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field 
                  label="Número de solicitud" 
                  v-model="request.id" 
                  readonly 
                />
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field 
                  label="Solicitante" 
                  :value="nombre_completo" 
                  readonly
                />
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field 
                  label="Fecha de solicitud" 
                  :value="moment(request.created_at).format('DD-MM-YY')"
                  readonly
                />
              </v-col>
              <v-col md="12" cols="12">
                <v-textarea 
                  label="Descripción" 
                  v-model="request.descripcion" 
                  readonly
                />
              </v-col>
              <v-col md="12" cols="12" v-if="request.direccion">
                <v-textarea 
                  label="Dirección" 
                  v-model="request.direccion" 
                  readonly
                />
              </v-col>
              <v-col md="12" cols="12">
                <slot name="body"></slot>
                <slot name="items">
                <h2 class="mb-2">Listado consolidado de productos</h2>
                <v-divider class="mb-4"/>
                
                  <v-data-table :items="products" :headers="headers" :tiene_paginacion="false">
                    <template v-slot:item.oferta_producto="{ item }">
                      <span :style="item.CMOfertaProductoConvenio.deleted_at ? 'color: red' : ''">
                        {{ item.CMOfertaProductoConvenio.deleted_at ? `${item.CMOfertaProductoConvenio.CMProducto.nombre} - (Oferta no disponible)` : item.CMOfertaProductoConvenio.CMProducto.nombre }}
                      </span>
                    </template>
                    <template v-slot:item.adicional="{ item }" v-if="validacionCamposAdicionales">
                      {{ validacionMonto ?? item.monto ? `Monto: ${item.monto}` : null}}
                      {{ item.altura ? `Altura: ${item.altura}` : null}}
                      </br>
                      {{ item.base ? `Base: ${item.base}` : null}}
                    </template>
                  </v-data-table>
                </slot>
              </v-col>
            </v-row>
          </v-card-text>
          <div class="px-4 py-4 d-flex detalle-actions">
            <slot name="actions"></slot>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" style="max-width: 100%;" class="d-block primary ml-0 order-last" @click="loadHistory" :loading="history.isLoading">
                  Ver histórico de aprobaciones
                </v-btn>
              </template>
              <span> Ver histórico de aprobaciones</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="ml-0 order-last" :loading="history.isLoading" @click="goBack">
                  Volver
                </v-btn>
              </template>
              <span>Volver</span>
            </v-tooltip>
          </div>
        </v-card>
        <v-dialog v-model="dialog" scrollable>
          <app-time-line :items="bitacoras" @cerrar="dialog = false">
            <template v-slot:suffix="{ item }">
              <v-btn v-if="item.adjunto" small color="primary" @click.stop="loadAttachmentSolicitud(item.adjunto)">
                <v-icon class="mr-1" color="#FFFFFF !important">mdi mdi-attachment</v-icon>
                Ver
              </v-btn>
            </template>
          </app-time-line>
        </v-dialog>
        <PdfModal 
        :isOpen="isPdfModalOpen" 
        :source-loadable="pdfLoadable" 
        @on-visibility-change="handlePdfModalVisibility"
        :filename="filename"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
  import { SpinnerComponent } from '@/components/utils';
  import DataTableComponent from "@/components/DataTableComponent.vue";
  import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
  import moment from 'moment'
  import { formatDate } from '@/utils/datetime';
  import PdfModal from '@/components/PdfModal.vue';
  import { getFilenameFromPath } from '@/utils/data';

  
  export default {
    name: "DetallesSolicitud",
    components: { DataTableComponent, SpinnerComponent , PdfModal },
    props: {
      cartLoadable: { type: Object },
    },
    data: () => ({
      history: createLoadable([]),
      requestId: null,
      dialog: false,
       // PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: '',
    activePath: '',
    }),
    computed: {
      nombre_completo() {
        if (!this.request) return '';

        const names = ['primer_nombre', 'segundo_nombre', 'tercer_nombre', 'primer_apellido', 'segundo_apellido', 'apellido_casada'];
        const persona = this.request?.solCom_user?.Empleado?.Persona;
        if (!persona) return '';

        return names.reduce((prev, name) => `${prev} ${persona[name] ?? ''}`, '').trim();
      },
      bitacoras() {
        const bitacoras = this.history.data?.bitacoras ?? [];
        return bitacoras.map(({ descripcion, fecha, comentario, bitSolCom_seq, adjunto }) => ({
          descripcion,
          fecha: formatDate(fecha),
          comentario,
          color: bitSolCom_seq.retroceso_en_flujo ? 'red' : 'success',
          adjunto,
        }));
      },
      request() {
        return this.cartLoadable.data?.solicitud;
      },
      products() {
        return this.cartLoadable.data?.productosActualizados ?? [];
      },
      validacionMonto(){
      return this.products[0]?.CMOfertaProductoConvenio?.CMProducto.CmSubGrupo.monto
      },
      validacionCamposAdicionales() {
        const subGrupo = this.products[0]?.CMOfertaProductoConvenio?.CMProducto.CmSubGrupo;
        if (!subGrupo) return false;
        const manejoAltura = subGrupo.altura || subGrupo.altura_decimal
        const manejoBase = subGrupo.base || subGrupo.base_decimal

        return subGrupo.monto || manejoAltura  || manejoBase
      },
      headers() {
        const headers = [
          { text: 'Producto', value: 'oferta_producto', align: 'center', sortable: false },
          { text: 'Proveedor', align: 'center', value: 'CMOfertaProductoConvenio.Proveedor.nombre' },
          { text: 'Cantidad', value: 'cantidad', align: 'center', sortable: false },
          ...(!this.validacionMonto ? [{ align: 'center', sortable: false, text: 'Precio Unitario', value: 'precio_de_oferta' }] : []),
          ...(this.validacionCamposAdicionales ? [{ align: 'center', sortable: false, text: 'Información adicional', value: 'adicional' }] : []),
          { text: 'Descripción', value: 'CMOfertaProductoConvenio.CMProducto.descripcion', align: 'center', sortable: false },
          { text: 'SubTotal', value: 'subtotal', align: 'center', sortable: false },
        ];
        return headers;
      }
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      async loadHistory() {
        toggleLoadable(this.history);
        const { data } = await this.services.cmAprobacionSolicitudCompra.historial(this.requestId);
        setLoadableResponse(this.history, data);
        if (!isResponseSuccesful(data)) return;
  
        this.dialog = true;
      },
      handlePdfModalVisibility(visible) {
        this.isPdfModalOpen = visible;
      },
      async loadAttachmentSolicitud(path) {
        this.isPdfModalOpen = true;
        if (this.activePath === path) return;

        this.filename = getFilenameFromPath(path);
        this.activePath = path;
        toggleLoadable(this.pdfLoadable);
        const { data } = await this.services.cmAprobacionSolicitudCompra.loadAttachmentSolicitud({
          nombre: path,
        });

        setLoadableResponse(this.pdfLoadable, data, { isFile: true });
      },
    },
    created() {
      this.requestId = this.$route.params.id;
    },
  }
</script>
<style>
.detalle-actions {
  flex-wrap: wrap; 
  gap: 8px;
}

.detalle-actions button > span {
  display: block !important;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .detalle-actions button {
    width: 100%;
  }
}

</style>