<template>
    <Fragment>
        <template v-if="soloLectura">
            <v-btn v-if="hasUploadedFile" class="my-4" color="secondary" @click.stop="previewFile(adjuntos[0])">
                <v-icon color="#FFF !important" class="mr-1">mdi-eye-outline</v-icon>
                Ver adjunto
            </v-btn>
        </template>
        <div v-else class="d-flex flex-wrap flex-md-nowrap justify-center align-center my-4" style="gap: 16px;">
            <v-file-input
              v-model="attachment"
              label="PDF"
              placeholder="Seleccione un PDF"
              outlined
              light
              accept="application/pdf"
              hide-details
              class="flex-grow-2"
              style="width: 100%"
            />
            <v-btn class="flex-grow-1" color="primary" @click.stop="confirmFileUpload">
                <!-- <v-icon color="#FFF !important" class="mr-1">mdi-upload</v-icon> -->
                Subir
            </v-btn>
            <template v-if="hasUploadedFile">
                <v-btn class="flex-grow-1" @click.stop="previewFile(adjuntos[0])">
                    <!-- <v-icon class="mr-1">mdi-eye-outline</v-icon> -->
                    Ver
                </v-btn>
                <v-btn class="flex-grow-1" @click.stop="confirmFileDeletion(adjuntos[0])">
                    <!-- <v-icon class="mr-1">mdi-delete-outline</v-icon> -->
                    Eliminar
                </v-btn>
            </template>
        </div>
        <ConfirmationModalComponent 
            :is-open="fileUploadConfirmOpen"
            :is-loading="certificationFileUpload.isLoading"
            description="¿Desea guardar el archivo?"
            @confirm="uploadFile"
            @cancel="fileUploadConfirmOpen = false"
        />
        <ConfirmationModalComponent 
            :is-open="fileDeletionConfirmOpen"
            :is-loading="certificationFileDeletion.isLoading"
            description="¿Desea eliminar el archivo?"
            @confirm="deleteFile"
            @cancel="fileDeletionConfirmOpen = false"
        />
        <FeedbackModalComponent 
            :is-open="feedback.isOpen"
            :description="feedback.description"
            @on-close="closeFeedbackModal"
        />
        <PdfModal 
            :isOpen="isPdfModalOpen" 
            :source-loadable="certificationFilePreview" 
            @on-visibility-change="handlePdfModalVisibility"
            :filename="filename"
        />
    </Fragment>
</template>
<script>
import { ConfirmationModalComponent, FeedbackModalComponent } from '@/components/utils';
import { isResponseSuccesful } from '@/utils/loadable';
import { getFilenameFromPath } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { mapActions, mapState } from 'vuex';
import { Fragment } from 'vue-fragment';

export default {
    name: 'AdjuntosCertificacionInsumosCM',
    components: { ConfirmationModalComponent, FeedbackModalComponent, PdfModal, Fragment },
    props: {
        adjuntos: { type: Array },
        idSolicitudCompra: { type: Number, required: true },
        idFondoSolicitudNecesidad: { type: Number, required: true },
        soloLectura: { type: Boolean, required: false },
    },
    data: () => ({
        attachment: null,
        fileUploadConfirmOpen: false,
        fileDeletionConfirmOpen: false,
        fileToPreview: null,
        fileToDelete: null,
        isPdfModalOpen: false,
        filename: '',
        feedback: {
            isOpen: false,
            description: '',
        },
    }),
    computed: {
        ...mapState('cmAprobacionCompraElectronica', ['certificationFileUpload', 'certificationFilePreview', 'certificationFileDeletion']),
        hasUploadedFile() {
            return this.adjuntos.length > 0;
        },
    },
    methods: {
        ...mapActions('cmAprobacionCompraElectronica', ['uploadCertificationFile', 'previewCertificationFile', 'deleteCertificationFile']),
        confirmFileUpload() {
            if (!this.attachment) {
                this.pushAppMessage({ message: "Seleccione un archivo para subir.", type: "error" });                
                return;
            }
            this.fileUploadConfirmOpen = true;
        },
        confirmFileDeletion(file) {
            this.fileToDelete = file;
            this.fileDeletionConfirmOpen = true;
        },
        async uploadFile() {
            const formData = new FormData();
            formData.set('adjunto', this.attachment);

            const payload = {
                idSolicitudCompra: this.idSolicitudCompra, 
                idFondoSolicitudNecesidad: this.idFondoSolicitudNecesidad, 
                data: formData,
            };

            const response = await this.uploadCertificationFile(payload);
            this.fileUploadConfirmOpen = false;
            this.attachment = null;

            if (isResponseSuccesful(response)) {
                this.feedback = {
                    description: 'Archivo subido correctamente.',
                    isOpen: true,
                };
            }
        },
        async deleteFile() {
            const response = await this.deleteCertificationFile({
                certificationFileId: this.fileToDelete.id,
                idFondoSolicitudNecesidad: this.idFondoSolicitudNecesidad, 
            });
            this.fileDeletionConfirmOpen = false;
            this.fileToDelete = null;

            if (isResponseSuccesful(response)) {
                this.feedback = {
                    description: 'Archivo eliminado correctamente.',
                    isOpen: true,
                };
            }
        },
        async previewFile(file) {
            if (file.id === this.fileToPreview?.id) {
                this.isPdfModalOpen = true;
                return;
            };

            const path = file.ruta;
            this.filename = getFilenameFromPath(path);
            this.fileToPreview = file;
            this.isPdfModalOpen = true;
            this.previewCertificationFile(file.id);
        },
        handlePdfModalVisibility(visible) {
            this.isPdfModalOpen = visible;
        },
        closeFeedbackModal() {
            this.feedback = {
                description: '',
                isOpen: false,
            };
        }
    },
}
</script>