<template>
    <Fragment>
        <v-expansion-panels>
            <v-expansion-panel v-for="(fund) in fundItems" :key="fund.id">
                <v-expansion-panel-header v-slot:default>
                    <v-row no-gutters class="align-center">
                        <v-col class="flex-grow-1">
                            <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">{{ fund.fondo.cifrado_presupuestario }}</b>
                            <br />
                            <span class="d-inline-block mt-1">
                                ${{ Number(fund.monto).toFixed(2) }} disponibles
                            </span>
                        </v-col>
                        <v-col class="text-right flex-grow-0">
                            <div class="d-flex align-center pr-2" style="gap: 16px">
                                <v-btn 
                                    v-if="!checkAllItemsInFundAreCertified(fund)"
                                    color="primary" 
                                    class="mr-4"
                                    :disabled="(authTimeExpired || readOnly)"
                                    @click.stop="confirmCertificationAll(fund.productos, fund)"
                                >
                                    Certificar todos
                                </v-btn>
                                <!-- Adjuntos -->
                                <v-tooltip v-if="fund.adjuntos.length === 0" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-badge color="red" dot offset-x="7" offset-y="5">
                                            <v-icon v-on="on" v-bind="attrs" color="primary">mdi-paperclip</v-icon>
                                        </v-badge>
                                    </template>
                                    <span>Pendiente de adjunto</span>
                                </v-tooltip>

                                <!-- Certificación -->
                                <v-tooltip v-if="checkAllItemsInFundAreCertified(fund)" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" color="green">mdi-check</v-icon>
                                    </template>
                                    <span>Insumos certificados</span>
                                </v-tooltip>
                                <v-tooltip v-else top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" color="warning">mdi-alert-circle</v-icon>
                                    </template>
                                    <span>Insumos pendientes de certificación</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <AdjuntosCertificacionInsumosCM 
                        :solo-lectura="readOnly"
                        :adjuntos="fund.adjuntos"
                        :id-fondo-solicitud-necesidad="Number(fund.id)"
                        :id-solicitud-compra="Number(requestId)"
                    />
                    <v-expansion-panels>
                        <v-expansion-panel v-for="product in fund.productos" :key="product.id">
                            <v-expansion-panel-header v-slot:default>
                                <div class="d-flex justify-space-between align-center flex-wrap" style="gap: 16px;">
                                    <div class="d-flex align-center flex-wrap" style="gap: 16px;">
                                        <img class="mx-auto" style="width: 50px; height: 50px; object-fit: cover; float: left;" :src="product.foto" :alt="product.nombre" />
                                        <div>
                                            <p class="mb-0 text-h6 font-weight-bold text-uppercase" style="line-height: 1.5rem !important">{{ product.nombre }}</p>
                                            <p v-if="product.certificacion" class="mb-0 mt-1">
                                                Usando ${{ Number(product.monto_a_certificar).toFixed(2) }} de
                                                <b>
                                                    ${{ Number(product.certificacion.monto).toFixed(2) }} certificados
                                                </b>
                                            </p>
                                            <p v-else class="mb-0 mt-1">${{ Number(product.monto_a_certificar).toFixed(2) }} a certificar</p>
                                        </div>
                                    </div>
                                    <div>
                                        <v-chip v-if="!product.certificacion && (authTimeExpired || readOnly)" class="mr-4" label>
                                            Pendiente
                                        </v-chip>
                                        <v-btn 
                                            v-if="!product.certificacion && !authTimeExpired && !readOnly" 
                                            color="primary" 
                                            class="mr-4"
                                            :disabled="certification.isLoading"
                                            @click.stop="confirmCertification(product, fund)"
                                        >
                                            Certificar
                                        </v-btn>
                                        <v-chip v-if="product.certificacion" class="mr-4" color="green" text-color="white" label>
                                            Certificado
                                        </v-chip>
                                    </div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-divider />
                                <DataTableComponent 
                                    :headers="headers"
                                    class="mt-4"
                                    :items="product.items_en_solicitud"
                                    :tiene_paginacion="false"
                                >
                                <template v-slot:item.precio_de_oferta="{ item }">
                                    ${{ Number(item.precio_de_oferta).toFixed(2) }}
                                </template>
                                <template v-slot:item.subtotal="{ item }">
                                    ${{ Number(item.subtotal).toFixed(2) }}
                                </template>
                                <template v-slot:item.adicional="{ item }">
                                    <span v-if="validacionCamposAdicionales(product.CmSubGrupo)">
                                       {{ validacionMonto ?? item.monto ? `Monto: ${item.monto}` : ``}}
                                        {{ item.altura ? `Altura: ${item.altura}` : null}}
                                        </br>
                                        {{ item.base ? `Base: ${item.base}` : null}}
                                    </span>
                                    <span v-else>-</span>
                                </template>
                                </DataTableComponent>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <ConfirmationModalComponent 
            :isOpen="confirmationOpenCertificarAll" 
            :description="'¿Desea certificar todos los productos?'"
            @confirm="handleConfirmationAll" 
            @cancel="confirmationOpenCertificarAll = false"
            :isLoading="certification.isLoading" 
        />

        <ConfirmationModalComponent 
            :isOpen="confirmationOpen" 
            :description="confirmMessage"
            @confirm="handleConfirmation" 
            @cancel="confirmationOpen = false"
            :isLoading="certification.isLoading" 
        />

        <FeedbackModalComponent 
            :isOpen="isFeedbackOpen"
            type="success" 
            :description="feedbackMessage"
            buttonText="Cerrar"
            @on-close="isFeedbackOpen = false"
        />
    </Fragment>
</template>
<script>
import { isResponseSuccesful } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent, FeedbackModalComponent } from '@/components/utils';
import AdjuntosCertificacionInsumosCM from './AdjuntosCertificacionInsumosCM.vue';
import { mapState, mapActions } from 'vuex';
import { Fragment } from 'vue-fragment';

export default {
    name: 'CertificacionInsumosSolicitudCm',
    components: { 
        AdjuntosCertificacionInsumosCM,
        ConfirmationModalComponent, 
        FeedbackModalComponent, 
        DataTableComponent, 
        Fragment, 
    },
    props: {
        requestId: {
            type: Number,
        },
        authTimeExpired: {
            type: Boolean,
        },
        readOnly: {
            type: Boolean,
        },
    },
    data: () => ({
        certificationData: {
            idFondoSolicitudNecesidad: null,
            productoSolicitud: null,
            idFondo: null,
        },
        // Confirmation
        confirmationOpen: false,
        confirmationOpenCertificarAll: false,
        isFeedbackOpen: false,
        feedbackMessage: '',
    }),
    computed: {
        ...mapState("cmAprobacionCompraElectronica", ["funds", "certification","productosPorProveedor"]),
        fundItems() {
            return this.funds.data;
        },
        validacionMonto() {
            return this.fundItems.some((item) => item.subGrupo.some(sg => sg.monto));
        },
        headers() {
            const tableHeaders = [
                { align: 'center', sortable: false, text: 'Proveedor', value: 'proveedor.nombre_comercial' },
                { align: 'center', sortable: false, text: 'Cantidad', value: 'cantidad' },
                ...(!this.validacionMonto ? [{ align: 'center', sortable: false, text: 'Precio', value: 'precio_de_oferta' }] : []), 
                ...(this.validacionCamposAdicionales ? [{ align: 'center', sortable: false, text: 'Información adicional', value: 'adicional' }] : []),
                { align: 'center', sortable: false, text: 'Sub total', value: 'subtotal' },
            ];
            return tableHeaders;
        },
        confirmMessage() {
            if (!this.certificationData.idFondoSolicitudNecesidad || !this.certificationData.producto) return '';
            const nombre = this.certificationData.producto.nombre;
            const monto = Number(this.certificationData.producto.monto_a_certificar).toFixed(2);

            return `¿Desea certificar el producto'${nombre}' por un monto de $${monto}?`;
        },
    },
    methods: {
        validacionCamposAdicionales(subGrupo) {
            if (!subGrupo) return false;
            
            const inputs = [
                subGrupo.monto,
                subGrupo.altura,
                subGrupo.altura_decimal,
                subGrupo.base,
                subGrupo.base_decimal,
            ];
            
            const valoresInputs = inputs.some(valor => valor);

            return valoresInputs;
        },
        ...mapActions("cmAprobacionCompraElectronica", ["loadItemsGroupByFund", "certifyItem","certifyItemAll"]),
        checkAllItemsInFundAreCertified(fund) {
            return fund.productos.every((producto) => producto.certificacion);
        },
        confirmCertification(product, fund) {
            this.certificationData = {
                idFondoSolicitudNecesidad: fund.id,
                producto: product,
            },
            this.confirmationOpen = true;
        },
        confirmCertificationAll(productos, fund) {
            this.certificationData = {
                idFondoSolicitudNecesidad: fund.id,
                productos: productos,
            },
            this.confirmationOpenCertificarAll = true;
        },
        async handleConfirmationAll() {
            const productosPayload = this.certificationData.productos.map(producto => {
                return {
                    id: producto.id,
                    monto: producto.monto_a_certificar
                };
            });

            const payload = {
                idFondoSolicitudNecesidad: this.certificationData.idFondoSolicitudNecesidad,
                productos: productosPayload,
                idSolicitudCompra: this.requestId,
            };
                        
            const data = await this.certifyItemAll(payload);
            this.confirmationOpenCertificarAll = false;

            this.certificationData = {
                fondoSolicitudNecesidad: null,
                productoSolicitud: null,
            };

            if (!isResponseSuccesful(data)) return;
            this.feedbackMessage = data.message;
            this.isFeedbackOpen = true;
            this.loadItemsGroupByFund(this.requestId);
        },
        
        async handleConfirmation() {
            const payload = {
                idFondoSolicitudNecesidad: this.certificationData.idFondoSolicitudNecesidad,
                idProducto: this.certificationData.producto.id,
                monto: this.certificationData.producto.monto_a_certificar,
                idSolicitudCompra: this.requestId,
            };
            const data = await this.certifyItem(payload);
            this.confirmationOpen = false;
            this.certificationData = {
                fondoSolicitudNecesidad: null,
                productoSolicitud: null,
            };
            if (!isResponseSuccesful(data)) return;
            this.feedbackMessage = data.message;
            this.isFeedbackOpen = true;
        },
    },
    created() {
        this.loadItemsGroupByFund(this.requestId);
    },
}
</script>