<template>
    <Fragment>
        <h2 class="mb-2">Adjuntos</h2>
            <v-alert  v-if="alertaLoading && adjuntos" type="info" class="mb-4" dense>
                Por favor, cargar la orden de compra generada por el sistema firmada u autorizada en el apartado de Adjuntos.
            </v-alert>
        <v-divider class="mb-4" />
        <AttachmentLogs 
            v-if="logs"
            :headers="headers" 
            :logs="logs" 
            :file-preview="filePreview" 
            :file-deletion="fileDeletion"
            :file-save="fileSave"
            @on-preview="handleFilePreview" 
            @on-delete="handleFileDeletion"
            @on-save="handleFileSave"
            :id-proveedor="idProveedor"
        >
            <template v-slot:item.created_at="{ item }">
                <span>{{ formatDate(item.created_at) }}</span>
            </template>
        </AttachmentLogs>
        <FeedbackModalComponent 
            :is-open="isFeedbackOpen"
            :description="feedbackDescription"
            type="success"
            @on-close="handleFeedbackClose"
        />
    </Fragment>
</template>
<script>
import { ConfirmationModalComponent, AttachmentLogs, FeedbackModalComponent } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';
import { 
    createLoadable,
    isResponseSuccesful, 
    setLoadableResponse, 
    setPageableResponse, 
    toggleLoadable, 
    togglePageable,
} from '@/utils/loadable';
import { Observable } from '@/utils/observable.js';
import {mapState, mapActions} from 'vuex';

export default {
    name: 'AdjuntosProcesoAutorizacion',
    props: {
        requestId: {
            type: Number, required: true,
        },
        idProveedor: {
            type: Number,
        },
        readOnly: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        ConfirmationModalComponent, AttachmentLogs, Fragment, FeedbackModalComponent,
    },
    data: () => ({
        headers: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre_documento' },
            { align: 'center', sortable: false, text: 'Fecha de subida', value: 'created_at' },
        ],
        filePreview: createLoadable(null),
        fileDeletion: createLoadable(null),
        fileSave: createLoadable(null),
        isFeedbackOpen: false,
        idOfertaProducto: null,
        feedbackDescription: '',
    }),
    computed: {
        ...mapState("cmAprobacionCompraElectronica", ["adjuntosUcp"]),
        logs() {
            return this.adjuntosUcp.get(this.idProveedor);
        },
        adjuntos(){
            const adjuntos = this.adjuntosUcp.get(this.idProveedor)
            return adjuntos.data.length === 0;
        },
        alertaLoading(){
            const adjuntos = this.adjuntosUcp.get(this.idProveedor)
            return adjuntos?.isDirty && !adjuntos?.isLoading
        }
    },
    methods: {
        ...mapActions('cmAprobacionCompraElectronica', ['cargarAdjuntosUCP']),
        async handleFilePreview(file) {
            toggleLoadable(this.filePreview);
            const { data } = await this.services.cmAprobacionSolicitudCompra.previewAuthFile(file.id);
            setLoadableResponse(this.filePreview, data, { isFile: true });
        },
        async handleFileDeletion(payload) {
            const { record } = payload;
            toggleLoadable(this.fileDeletion);
            const { data } = await this.services.cmAprobacionSolicitudCompra.deleteAuthFile(record.id);
            setLoadableResponse(this.fileDeletion, data);

            if (!isResponseSuccesful(data)) return;
            this.feedbackDescription = data.message;
            this.isFeedbackOpen = true;
            this.loadFiles();
            Observable.emit("refresh-adjuntos")
        },
        async handleFileSave(payload) {
            const formData = new FormData();
            formData.set('adjunto', payload.file);
            formData.set('nombre', payload.name);
            formData.set('id_proveedor', this.idProveedor)

            toggleLoadable(this.fileSave);
            const { data } = await this.services.cmAprobacionSolicitudCompra.saveAuthFile(this.requestId, formData);
            setLoadableResponse(this.fileSave, data);
            
            if (!isResponseSuccesful(data)) return;
            this.feedbackDescription = data.message;
            this.isFeedbackOpen = true;
            this.loadFiles();
            Observable.emit("refresh-adjuntos")
        },
        handleFeedbackClose() {
            this.isFeedbackOpen = false;
        },
        formatDate,
        // API DATA
        async loadFiles() {
            if (!this.idProveedor) return;
            this.cargarAdjuntosUCP({ idProveedor: this.idProveedor, idSolicitud: this.requestId });
        },
    },
    created() {
        this.loadFiles();
    },
}
</script>